<template>
  <div>
    <!-- Info -->
    <v-card class="edit-card" v-if="canEditCompanyInformation">
      <v-card-title class="title">
        <span>Company Information</span>
        <span class="action-buttons" key="whatwhat">
          <span v-if="!isCompanySectionComplete" class="incomplete">
            <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
            Incomplete
          </span>
        </span>
      </v-card-title>
      <v-card-text class="data">
        <div class="edit-section">
          <div>
            <div>
              <strong>Name:</strong>
              {{ companyObj.name }}
            </div>
            <div>
              <strong>RIA Name:</strong>
              <span v-html="companyRIA"></span>
            </div>
            <div>
              <strong>Phone:</strong>
              <span v-html="companyPhone"></span>
            </div>
          </div>
          <div>
            <a @click="editCompanyInformation()">Edit</a>
          </div>
        </div>
        <div class="edit-section separator">
          <div>
            <strong>Address:</strong><br />
            <span v-html="companyAddress"></span>
          </div>
          <div>
            <a @click="editCompanyAddress()">Edit</a>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- DocuSign -->
    <v-card class="edit-card" v-if="canLinkDocusign">
      <v-card-title class="title">
        <span>
          <img
            class="docusign-logo"
            height="22"
            :src="`${publicPath}images/docusign-logo.png`"
          />
        </span>
        <span class="action-buttons">
          <span v-if="!isDocusignComplete" class="optional">
            <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
            Optional
          </span>
          <a v-if="!isDocusignComplete" @click="linkDocusign()"> Link </a>
          <a class="unlink" v-else @click="unlinkDocusign()"> Unlink </a>
        </span>
      </v-card-title>
      <v-card-text class="data">
        <div v-if="isDocusignComplete" class="linked">
          <v-icon>fas fa-check-circle</v-icon>
          <span>Linked</span><br />
          <v-row>
            <v-col cols="3" class="field-label">Username</v-col>
            <v-col cols="9"
              ><span v-html="docusignStatus.accountUsername"></span
            ></v-col>
          </v-row>
          <v-row >
            <v-col cols="3" class="field-label">Selected Account</v-col>
            <v-col cols="9" v-if="docusignStatus && docusignAccounts && docusignAccounts.length === 1"
              ><span v-html="docusignStatus.accountName"></span
            ></v-col>
            <v-col cols="6" v-else-if="docusignStatus && docusignAccounts && docusignAccounts.length > 1">
              <v-select
                class="align-top"
                v-model="selectedDocusignAccount"
                :items="docusignAccounts"
                item-text="accountName"
                item-value="accountId"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="3" v-if="docusignStatus && docusignAccounts && docusignAccounts.length > 1">
              <a
                class="btn mini"
                :class="{
                  disabled:
                    selectedDocusignAccount.accountId ===
                    docusignStatus.accountId,
                }"
                @click="saveSelectedAccount"
                id="save-account"
                >Change Account</a
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="field-label">Account Ownership</v-col>
            <v-col cols="9">
              <v-btn-toggle
                v-model="docusignStatus.accountOwnership"
                @change="saveAccountOwnership"
                mandatory
              >
                <v-btn value="Firm">Firm</v-btn>
                <v-btn value="Schwab">Schwab</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </div>
        <div v-else class="not-linked">
          <v-icon>fas fa-times-circle</v-icon>
          <span>Not Linked</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- CRM -->
    <v-card class="edit-card" v-if="canEditCRM">
      <v-card-title class="title">
        <span>CRM</span>
      </v-card-title>
      <v-card-text class="data">
        <div class="linked">
          <v-row>
            <v-col cols="3" class="field-label">Selected CRM</v-col>
            <v-col cols="6">
              <v-select
                class="align-top"
                v-model="selectedCRM"
                :items="crms"
                item-text="crm_name"
                item-value="crm_id"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="3">
              <a
                class="btn mini"
                :class="{
                  disabled: false,
                }"
                @click="saveSelectedCrm(selectedCRM)"
                id="save-account"
                >Change CRM</a
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <!-- Documents -->
    <v-card class="edit-card">
      <v-card-title class="title">
        <span> Documents </span>
        <span
          v-if="companyObj && !isDocumentSectionComplete"
          class="incomplete"
        >
          <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
          Incomplete
        </span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12 upload-col">
            <div>
              <strong> ADV 2A: </strong>
              <span class="upload-date" v-if="getAsset(ASSET_KEYS.ADV2A)">
                <a @click="downloadDocument(ASSET_KEYS.ADV2A)">
                  {{ adv2aName }}
                </a>
                <b> ({{ adv2aDate }})</b>
              </span>
              <span v-else v-html="missingString"></span>
            </div>
            <div>
              <v-file-input
                v-model="documents.adv2a"
                prepend-icon=""
                class="faux-upload"
                ref="upload-2A"
                hide-details="auto"
                :clearable="false"
                accept="application/pdf"
                @change="uploadADV2A"
              >
                <template v-slot:prepend-inner>
                  <a
                    id="upload-2a"
                    class="btn mini"
                    style="display: block"
                    @click="() => $refs['upload-2A'].$refs.input.click()"
                  >
                    <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                    choose file
                  </a>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
        <v-row class="separator">
          <v-col class="col-12 upload-col">
            <div>
              <strong> CRS: </strong>
              <span class="upload-date" v-if="getAsset(ASSET_KEYS.CRS)">
                <a @click="downloadDocument(ASSET_KEYS.CRS)">{{ crsName }}</a>
                <b> ({{ crsDate }})</b>
              </span>
              <span v-else>( optional )</span>
            </div>
            <div>
              <v-file-input
                v-model="documents.crs"
                prepend-icon=""
                class="faux-upload"
                ref="upload-CRS"
                hide-details="auto"
                accept="application/pdf"
                :clearable="false"
                @change="uploadCRS"
              >
                <template v-slot:prepend-inner>
                  <a
                    id="upload-crs"
                    class="btn mini"
                    style="display: block"
                    @click="() => $refs['upload-CRS'].$refs.input.click()"
                  >
                    <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                    choose file
                  </a>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
        <v-row class="separator" v-if="companyObj !== null">
          <v-col class="col-12 upload-col">
            <div>
              <strong> Advisory Agreement: </strong>
              <span
                class="upload-date"
                v-if="getAsset(ASSET_KEYS.ADVISORY_AGREEMENT)"
              >
                <a @click="downloadDocument(ASSET_KEYS.ADVISORY_AGREEMENT)">
                  {{ aaName }}
                </a>
                <b> ({{ aaDate }})</b>
              </span>
              <span
                v-else
                v-html="
                  companyObj && !companyObj.docusignDisabled
                    ? missingString
                    : '( optional )'
                "
              ></span>
            </div>
            <div>
              <v-file-input
                v-model="documents.aa"
                prepend-icon=""
                class="faux-upload"
                ref="upload-AA"
                hide-details="auto"
                accept="application/pdf"
                :clearable="false"
                @change="uploadAA"
              >
                <template v-slot:prepend-inner>
                  <a
                    id="upload-aa"
                    class="btn mini"
                    style="display: block"
                    @click="() => $refs['upload-AA'].$refs.input.click()"
                  >
                    <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                    choose file
                  </a>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
        <v-row
          class="separator"
          v-if="
            companyObj !== null &&
            currentCompany &&
            !currentCompany.transitionsDisabled
          "
        >
          <v-col class="col-12 upload-col">
            <div>
              <strong> Non Solicit: </strong>
              <span class="upload-date" v-if="getAsset(ASSET_KEYS.NonSolicit)">
                <a @click="downloadDocument(ASSET_KEYS.NonSolicit)">
                  {{ nonSolicitName }}
                </a>
                <b> ({{ nonSolicitDate }})</b>
              </span>
              <span v-else v-html="missingString"></span>
            </div>
            <div>
              <v-file-input
                v-model="documents.nonSolicit"
                prepend-icon=""
                class="faux-upload"
                ref="upload-non-Solicit"
                hide-details="auto"
                accept="application/pdf"
                :clearable="false"
                @change="uploadNonSolicit"
              >
                <template v-slot:prepend-inner>
                  <a
                    id="upload-non-solicit"
                    class="btn mini"
                    style="display: block"
                    @click="
                      () => $refs['upload-non-Solicit'].$refs.input.click()
                    "
                  >
                    <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                    choose file
                  </a>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Master Accounts -->
    <v-card class="edit-card" v-if="companyObj !== null">
      <v-card-title class="title">
        <span> Master Account Numbers </span>
        <span
          v-if="!companyObj.docusignDisabled && !isAccountSectionComplete"
          class="incomplete"
        >
          <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
          Incomplete
        </span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col>
            <span class="warning">
              <v-icon small> fas fa-exclamation-triangle </v-icon>
              Add ALL Master Account Numbers for the Company.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="add-man-col">
            <v-text-field
              outlined
              maxlength="9"
              :aria-required="companyObj && !companyObj.docusignDisabled"
              v-model.trim="masterAccountNumber"
              :backgroundColor="inputBackgroundColor"
              :error-messages="masterAccountNumberErrors"
              hide-details="auto"
              class="man mb-2 mr-2"
              dense
              ref="man-input"
              autocomplete="off"
              placeholder="NNNN-NNNN"
            >
            </v-text-field>
            <a class="btn mini" @click="addMasterAccountNumber"
              >Add Master Account Number</a
            >
          </v-col>
        </v-row>
        <div class="man-list">
          <v-chip
            v-for="man in masterAccountNumbers"
            :key="man"
            class="ma-2"
            close
            :color="primaryColor"
            outlined
            @click:close="deleteMasterAccountNumber(man)"
          >
            {{ man }}
          </v-chip>
          <div
            class="missing"
            v-if="
              companyObj &&
              !companyObj.docusignDisabled &&
              masterAccountNumbers.length === 0
            "
          >
            At least one Master Account Number is required.
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Custom Questions -->
    <v-card class="edit-card">
      <v-card-title class="title">
        <span>Custom Questions</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <a @click="manageCustomQuestions()" class="btn mini primary">
              Manage Custom Questions
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Transitions Message Templates -->
    <v-card
      class="edit-card"
      v-if="companyObj && !companyObj.transitionsDisabled"
    >
      <v-card-title class="title">
        <span>Messages Templates</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <a @click="manageMessageTemplates()" class="btn mini primary">
              Manage Messages Templates
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Notifications -->
    <v-card
      class="edit-card"
      v-if="companyObj && !companyObj.transitionsDisabled"
    >
      <v-card-title class="title">
        <span>Transition Notifications</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col>
            <span class="">Allow Transition Announcements</span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-switch
              v-model="transitionNotificationsEnabled"
              label=""
              class="mt-0"
              color="blue"
              :model-value="true"
              @change="toggleTransitionNotifications"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- SMS Notifications -->
    <v-card
      class="edit-card"
      v-if="companyObj"
    >
      <v-card-title class="title">
        <span>SMS Notifications</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col>
            <span class="">Allow SMS Notifications</span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-switch
              v-model="smsNotificationsEnabled"
              label=""
              class="mt-0"
              color="blue"
              @change="toggleSmsNotifications"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Logo -->
    <v-card class="edit-card" v-if="canManageTheme">
      <v-card-title class="title">
        <span>Customize</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12 col-sm-4">
            <div class="logo-upload">
              <div
                @drop.prevent="addLogo"
                @dragover.prevent
                v-if="!hasCustomLogo"
                id="no-logo-wrapper"
              >
                <div>
                  Drag &amp; drop logo
                  <span class="camera-logo">
                    <v-icon small>fas fa-camera</v-icon>
                  </span>
                </div>
                <div>
                  or
                  <v-file-input
                    v-model="customLogo"
                    prepend-icon=""
                    class="faux-upload"
                    ref="upload"
                    hide-details="auto"
                    accept="image/png, image/jpeg, image/svg+xml, image/gif"
                    @change="uploadLogo"
                  >
                    <template v-slot:prepend-inner>
                      <a
                        id="upload"
                        class="upload"
                        style="display: block"
                        @click="() => $refs['upload'].$refs.input.click()"
                      >
                        choose file
                      </a>
                    </template>
                  </v-file-input>
                </div>
              </div>
              <img v-if="base64logo !== null" :src="base64logo" height="45" />
              <img v-if="hasCustomLogo" :src="logo" height="45" />
            </div>
            <div v-if="canReplaceLogo">
              <a style="display: block" @click="clearLogo"> Replace </a>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-4 theme-button">
            <div>
              <a @click="saveThemeData" id="save-theme" class="btn mini">
                Save Theme
              </a>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import Themable from '@/common/mixins/themable.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import {
  formatDate,
  formatPhoneLocal,
  COMPANY_PROFILE_SECTION_TYPES,
  ASSET_KEYS,
  MASTER_ACCOUNT_REGEX,
  US_DATE_SHORT,
  MENUS,
  USER_ROLES,
} from '@/common/constants';
import {
  GET_COMPANY,
  UPLOAD_COMPANY_ASSET,
  COMPANY_ASSETS,
  GET_CURRENT_USER,
  MASTER_ACCOUNT_NUMBER,
  ADD_MASTER_ACCOUNT_NUMBER,
  DELETE_MASTER_ACCOUNT_NUMBER,
  GET_DOCUSIGN_CONSENT_URL,
  CHOOSE_DOCUSIGN_ACCOUNT,
  CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP,
  UNLINK_DOCUSIGN,
  UPDATE_COMPANY_CRM,
  GET_AVAILABLE_CRMS,
  GET_CURRENT_COMPANY,
  DOWNLOAD_COMPANY_ASSET,
  TOGGLE_TRANSITION_NOTIFICATIONS,
  TOGGLE_SMS_NOTIFICATIONS,
  UPLOAD_LOGO,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
/* Custom Validators */
const validateAccountNumber = (value) => {
  if (!value) return true;

  return MASTER_ACCOUNT_REGEX.test(value);
};
const duplicateAccountNumber = (numbers) => (value) => {
  const idx = numbers.indexOf(value);
  return idx === -1;
};

export default {
  name: 'CompanyProfile',
  props: {
    id: Number,
    default: null,
  },
  data: () => ({
    crms: null,
    companyObj: null,
    hasCustomLogo: false,
    base64logo: null,
    customLogo: null,
    assets: null,
    documents: {
      adv2a: null,
      crs: null,
      aa: null,
      nonSolicit: null,
    },
    masterAccountNumber: null,
    masterAccountNumbers: [],
    docusignAccounts: null,
    selectedDocusignAccount: null,
    docusignStatus: {
      accountId: null,
      accountUsername: null,
      accountName: null,
      accountOwnership: null,
      enabled: false,
    },
    selectedCRM: null,
  }),
  validations() {
    return {
      masterAccountNumber: {
        required,
        validateAccountNumber,
        dups: duplicateAccountNumber(this.masterAccountNumbers),
      },
    };
  },

  mixins: [validationMixin, PublicAssets, Themable, EventListener],
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),

    transitionNotificationsEnabled() {
      return (
        this.currentCompany &&
        !this.currentCompany.transitionNotificationsDisabled
      );
    },

    smsNotificationsEnabled() {
      return (
        this.currentCompany &&
        this.currentCompany.smsNotificationsEnabled
      );
    },

    masterAccountNumberErrors() {
      const errors = [];
      if (!this.$v.masterAccountNumber.$dirty) return errors;
      !this.$v.masterAccountNumber.required &&
        errors.push('A value is required');
      !this.$v.masterAccountNumber.validateAccountNumber &&
        errors.push('Must be in the format NNNN-NNNN or NNNNNNNN');
      !this.$v.masterAccountNumber.dups && errors.push('Value already exists');
      return errors;
    },

    isSetupComplete() {
      return this.companyObj && this.companyObj.setupComplete;
    },
    canEditCompanyInformation() {
      return (
        this.companyObj !== null &&
        PermissionService.hasPermission(
          PERMISSIONS.COMPANY.MANAGE_COMPANY_PROFILE,
        )
      );
    },
    canLinkDocusign() {
      const isDocusignEnabled = this.docusignStatus.enabled;
      const isCompanyAdming =
        this.currentUser.roles[0].id === USER_ROLES.COMPANY_ADMIN.id;

      return [
        this.canManageIntegrations,
        isDocusignEnabled,
        isCompanyAdming,
      ].every((x) => x);
    },
    canManageTheme() {
      return this.canEditCompanyInformation;
    },
    canManageIntegrations() {
      return (
        this.canEditCompanyInformation &&
        this.companyObj !== null &&
        !this.companyObj.docusignDisabled
      );
    },
    canEditCRM() {
      const isSuperAdmin =
        this.currentUser.roles[0].id === USER_ROLES.PRODUCT_ADMIN.id;
      const crmInitialized =
        this.currentCompany && this.currentCompany.crmInitialized;

      return [isSuperAdmin, !crmInitialized].every((x) => x);
    },
    canReplaceLogo() {
      if (!this.currentCompany) return false;
      return this.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO,
      );
    },
    logo() {
      if (!this.currentCompany) return '';
      const logo = this.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO,
      );
      return logo ? logo.assetLocation : '';
    },
    companyRIA() {
      return this.companyObj && this.companyObj.iaFirmName
        ? this.companyObj.iaFirmName
        : this.missingString;
    },
    companyPhone() {
      return this.companyObj && this.companyObj.phone
        ? formatPhoneLocal(this.companyObj.phone)
        : this.missingString;
    },
    companyAddress() {
      let address = null;
      if (this.companyObj) {
        address = this.companyObj.address1
          ? `${this.companyObj.address1}<br>
          ${this.companyObj.address2 ? this.companyObj.address2 + '<br>' : ''}
          ${this.companyObj.city} ${this.companyObj.state}, ${
              this.companyObj.zip
            }`
          : null;
      }
      return address ? address : this.missingString;
    },
    adv2aName() {
      const asset = this.getAsset(ASSET_KEYS.ADV2A);
      return asset ? asset.assetId : '';
    },
    adv2aDate() {
      const asset = this.getAsset(ASSET_KEYS.ADV2A);
      return asset ? formatDate(asset.createdOn, US_DATE_SHORT) : '';
    },
    crsName() {
      const asset = this.getAsset(ASSET_KEYS.CRS);
      return asset ? asset.assetId : '';
    },
    crsDate() {
      const asset = this.getAsset(ASSET_KEYS.CRS);
      return asset ? formatDate(asset.createdOn, US_DATE_SHORT) : '';
    },
    aaName() {
      const asset = this.getAsset(ASSET_KEYS.ADVISORY_AGREEMENT);
      return asset ? asset.assetId : '';
    },
    aaDate() {
      const asset = this.getAsset(ASSET_KEYS.ADVISORY_AGREEMENT);
      return asset ? formatDate(asset.createdOn, US_DATE_SHORT) : '';
    },
    nonSolicitName() {
      const asset = this.getAsset(ASSET_KEYS.NonSolicit);
      return asset ? asset.assetId : '';
    },
    nonSolicitDate() {
      const asset = this.getAsset(ASSET_KEYS.NonSolicit);
      return asset ? formatDate(asset.createdOn, US_DATE_SHORT) : '';
    },
    isDocumentSectionComplete() {
      try {
        const has2A = this.assets.find((a) => a.assetType === ASSET_KEYS.ADV2A);
        const hasAA = this.assets.find(
          (a) => a.assetType === ASSET_KEYS.ADVISORY_AGREEMENT,
        );
        return has2A && hasAA;
      } catch {
        // Do nothing
      }
      return false;
    },
    isAccountSectionComplete() {
      return this.masterAccountNumbers.length > 0;
    },
    isCompanySectionComplete() {
      return this.companyObj &&
        this.companyObj.name &&
        this.companyObj.iaFirmName &&
        this.companyObj.phone &&
        this.companyObj.address1 &&
        this.companyObj.city &&
        this.companyObj.state &&
        this.companyObj.zip
        ? true
        : false;
    },
    isDocusignComplete() {
      if (this.currentCompany) {
        return this.currentCompany.docusignLinked;
      }
      return false;
    },
    docusignConsentCode() {
      return this.$route.query.code;
    },
  },
  methods: {
    manageMessageTemplates() {
      this.$router.push({ name: MENUS.MESSAGE_TEMPLATES.id });
    },
    manageCustomQuestions() {
      this.$router.push({ name: MENUS.CUSTOM_QUESTIONS.id });
    },

    /* Get asset */
    getAsset(type) {
      return this.assets && this.assets.find((a) => a.assetType === type);
    },

    /* Get the base64 representation of an image */
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    /* Upload logo */
    uploadLogo(selectedFile) {
      const payload = {
        assetKey: ASSET_KEYS.LOGO,
        file: selectedFile,
        organisationId: this.companyObj.id,
      };

      // dispatch UPLOAD_LOGO action
      this.$store
        .dispatch(UPLOAD_LOGO, payload)
        .then(() => {
          // Reload the page to pick up the new asset
          EventService.emit('toggle-loading', true);
          this.$router.go();
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error saving theme!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {});
    },

    /* Add a logo via drop */
    addLogo(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      const logo = [...droppedFiles][0];
      this.uploadLogo(logo);
    },

    /* Clear the displayed logo */
    clearLogo() {
      this.customLogo = null;
      this.base64logo = null;
      this.hasCustomLogo = false;
      this.logoFile = null;
    },

    /* Emit event to display dialog for editing Company info */
    editCompanyInformation() {
      EventService.emit(
        'display-add-edit-company-modal',
        this.companyObj,
        COMPANY_PROFILE_SECTION_TYPES.BASIC,
      );
    },

    /* Emit event to display dialog for editing Company Address */
    editCompanyAddress() {
      EventService.emit(
        'display-add-edit-company-modal',
        this.companyObj,
        COMPANY_PROFILE_SECTION_TYPES.ADDRESS,
      );
    },

    /* Emit event to display dialog for editing docusign credentials */
    editDocusignInfo() {
      EventService.emit(
        'display-add-edit-company-modal',
        this.companyObj,
        COMPANY_PROFILE_SECTION_TYPES.DOCUSIGN,
      );
    },

    /* Process upload of ADV2A */
    uploadADV2A(selectedFile) {
      this.uploadDocument(ASSET_KEYS.ADV2A, selectedFile);
    },

    /* Process upload of CRS */
    uploadCRS(selectedFile) {
      this.uploadDocument(ASSET_KEYS.CRS, selectedFile);
    },

    /* Process upload of AA */
    uploadAA(selectedFile) {
      this.uploadDocument(ASSET_KEYS.ADVISORY_AGREEMENT, selectedFile);
    },
    /* Process upload of Non solicit */
    uploadNonSolicit(selectedFile) {
      this.uploadDocument(ASSET_KEYS.NonSolicit, selectedFile);
    },

    /* Upload a document */
    uploadDocument(type, file) {
      const payload = {
        assetKey: type,
        file: file,
        organisationId: this.companyObj.id,
      };

      this.$store
        .dispatch(UPLOAD_COMPANY_ASSET, payload)
        .catch((response) => {
          this.loadProfileData(this.id);
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error uploading document!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.checkCompanyComplete();
        });
    },

    /* Download a document */
    downloadDocument(type) {
      const asset = this.getAsset(type);
      if (!asset) return;

      const payload = {
        assetId: asset.assetId,
        organisationId: this.companyObj.id,
      };

      this.$store
        .dispatch(DOWNLOAD_COMPANY_ASSET, payload)
        .then((response) => window.open(response, '_blank'))
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error downloading document!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Delete a document */
    deleteDocument(type) {
      const asset = this.getAsset(type);
      if (!asset) return;

      //  TODO:  Implement this action
    },

    /* Check Company Status */
    checkCompanyComplete(postCompleteFocusRef = null) {
      this.loadProfileData(this.id)
        .then(() => this.getAvailableCrms())
        .then(() => this.$store.dispatch(GET_CURRENT_USER))
        .then(() => this.$store.dispatch(GET_CURRENT_COMPANY))
        .then(() => {
          {
            if (postCompleteFocusRef) {
              this.$nextTick(() => this.$refs[postCompleteFocusRef].focus());
            }
          }
        });
    },

    /* Get Master Account Numbers */
    getMasterAccountNumbers() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$store
            .dispatch(MASTER_ACCOUNT_NUMBER, {
              organisationId: this.companyObj.id,
            })
            .then((response) => {
              this.masterAccountNumbers = response;
            })
            .catch((response) => {
              NotificationService.alert(
                {
                  type: NOTIFICATION_TYPES.ERROR,
                  title: 'Error',
                  message: 'Error getting Master Account Numbers!',
                  okTitle: 'Ok',
                },
                response,
              );
            })
            .finally(() => {
              resolve();
            });
        }, 500);
      });
    },

    /* Add a Master Account Number */
    addMasterAccountNumber() {
      this.$v.masterAccountNumber.$touch();
      if (this.$v.masterAccountNumber.$invalid) return;

      // Insert dash if missing
      let man = this.masterAccountNumber;
      if (!man.includes('-')) {
        man = `${man.slice(0, 4)}-${man.slice(4)}`;
      }

      const payload = { organisationId: this.companyObj.id, man };

      this.$store
        .dispatch(ADD_MASTER_ACCOUNT_NUMBER, payload)
        .then(() => {
          this.$v.masterAccountNumber.$reset();
          this.masterAccountNumber = null;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error adding Master Account Number!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.checkCompanyComplete('man-input');
        });
    },

    /* Delete a Master Account Number */
    deleteMasterAccountNumber(man) {
      const payload = { organisationId: this.companyObj.id, man };

      this.$store
        .dispatch(DELETE_MASTER_ACCOUNT_NUMBER, payload)
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error deleting Master Account Number!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.checkCompanyComplete('man-input');
        });
    },
    saveSelectedCrm(selectedCRM) {
      const payload = { organisationId: this.companyObj.id, selectedCRM };

      this.$store.dispatch(UPDATE_COMPANY_CRM, payload).catch((response) => {
        NotificationService.alert(
          {
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: 'Error changing CRM!',
            okTitle: 'Ok',
          },
          response,
        );
      });
    },
    saveSelectedAccount() {
      if (
        this.selectedDocusignAccount.accountId !==
        this.docusignStatus.accountId
      ) {
        this.$store
          .dispatch(CHOOSE_DOCUSIGN_ACCOUNT, this.selectedDocusignAccount)
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Error changing DocuSign account!',
                okTitle: 'Ok',
              },
              response,
            );
          })
          .finally(() => {
            this.loadCompany(this.id);
          });
      }
    },
    saveAccountOwnership() {
      this.$store
        .dispatch(
          CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP,
          this.docusignStatus.accountOwnership,
        )
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error changing DocuSign account ownership!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          EventService.emit('reload-current-user');
        });
    },
    /* Save Theme Data */
    saveThemeData() {
      const payload = {
        assetKey: ASSET_KEYS.LOGO,
        file: this.logoFile,
        organisationId: this.companyObj.id,
      };
      this.$store
        .dispatch(UPLOAD_COMPANY_ASSET, payload)
        .then(() => {
          // Reload the page to pick up the new asset
          EventService.emit('toggle-loading', true);
          this.$router.go();
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error saving theme!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {});
    },
    /* Load the company data for a specific id */
    loadCompany(id) {
      this.companyObj = null;

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(GET_COMPANY, id)
          .then((response) => {
            this.companyObj = response;
            this.getMasterAccountNumbers();
            this.getDocusignStatus();
            this.hasCustomLogo = this.companyObj.assets
              ? this.companyObj.assets.find(
                  (asset) => asset.assetType === ASSET_KEYS.LOGO,
                )
              : false;
          })
          .then(() => this.$store.dispatch(GET_CURRENT_COMPANY))
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'error retrieving company!',
                okTitle: 'Ok',
                okMethod: () => {
                  this.$router.push({
                    name: PermissionService.getHomePage(
                      this.currentUser.roles[0],
                    ),
                  });
                },
              },
              response,
            );
            reject(response);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    /* Check Docusign Status */
    getDocusignStatus() {
      const { externalAccounts } = this.companyObj || {};

      if (!externalAccounts) {
        return;
      }

      const { integrationConfig } =
        externalAccounts.find(
          ({ integrationType }) => integrationType === 'DocumentSigning',
        ) || {};

      this.docusignStatus.enabled = !this.companyObj.docusignDisabled;

      if (integrationConfig) {
        this.selectedDocusignAccount = {
          accountId: integrationConfig.accountId,
          accountName: integrationConfig.accountName,
          accountOwnership: integrationConfig.accountOwnership,
          accountUsername: integrationConfig.username,
        };
        this.docusignStatus.accountId = integrationConfig.accountId;
        this.docusignStatus.accountUsername = integrationConfig.username;
        this.docusignStatus.accountOwnership =
          integrationConfig.accountOwnership;
        this.docusignStatus.accountName = integrationConfig.accountName;
        this.docusignStatus.enabled = integrationConfig.enabled;
        this.docusignAccounts = integrationConfig.accounts;
      }
    },

    /* Link Docusign Account */
    linkDocusign() {
      const payload = {
        organisationId: this.companyObj.id,
      };

      this.$store
        .dispatch(GET_DOCUSIGN_CONSENT_URL, payload)
        .then((data) => {
          window.location.replace(data);
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error contacting DocuSign!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Unlink Docusign Account */
    unlinkDocusign() {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to unlink DocuSign?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performUnlinkDocusign();
        },
      });
    },

    /* Perform Unlink Docusign Account */
    performUnlinkDocusign() {
      this.$store
        .dispatch(UNLINK_DOCUSIGN)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'Successfully unlinked DocuSign. You will need to Link to re-authenticate and complete your profile.',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              this.checkCompanyComplete();
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error unlinking from DocuSign.',
              okTitle: 'Ok',
              okMethod: () => {
                this.dialog = false;
                this.checkCompanyComplete();
              },
            },
            response,
          );
        });
    },

    /* Load Assets */
    loadAssets(id) {
      return new Promise((resolve) => {
        this.$store
          .dispatch(COMPANY_ASSETS, { organisationId: id })
          .then((response) => {
            this.assets = response.assets;
          })
          .catch(() => (this.assets = null))
          .finally(() => {
            resolve();
          });
      });
    },

    /* Load Profile Data */
    loadProfileData(id) {
      const companyInfoPromise = this.loadCompany(id);
      const assetPromise = this.loadAssets(id);
      const dsPromise = this.getDocusignStatus();
      // const themePromise = this.loadThemeData();

      return new Promise((resolve) => {
        Promise.all([
          companyInfoPromise,
          assetPromise,
          dsPromise,
          // themePromise,
        ]).finally(() => {
          resolve();
        });
      });
    },

    getAvailableCrms() {
      if (!this.companyObj) {
        return;
      }
      this.$store
        .dispatch(GET_AVAILABLE_CRMS, { companyId: this.companyObj.id })
        .then((response) => {
          this.crms = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting available CRMs.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    toggleTransitionNotifications() {
      this.$store
        .dispatch(TOGGLE_TRANSITION_NOTIFICATIONS, {
          companyId: this.companyObj.id,
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error toggling Transition Announcements.`,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    toggleSmsNotifications() {
      this.$store
        .dispatch(TOGGLE_SMS_NOTIFICATIONS, {
          companyId: this.companyObj.id,
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error toggling SMS notifications.`,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
  },
  created() {
    // Load initial user
    this.missingString = "<span class='missing'>( required )</span>";
    this.ASSET_KEYS = ASSET_KEYS;

    this.checkCompanyComplete();

    // Listen for reload
    this.listen('reload-company-profile', () => {
      this.checkCompanyComplete();
    });
    this.listen('reload-current-user', () => {
      this.$store.dispatch(GET_CURRENT_USER);
    });
  },
};
</script>

<style lang="scss" scoped>
.edit-card {
  padding: 32px;
  margin-bottom: 20px;

  .title {
    color: var(--color-text);
    margin-bottom: 1.5rem;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid var(--secondary-color);
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    align-items: flex-start;

    a {
      font-size: 16px;
    }

    .action-buttons {
      a {
        display: block;
        text-align: right;

        &.unlink {
          color: var(--primary-color);
        }
      }
    }

    .incomplete {
      font-size: 16px;
      color: var(--error-color);

      svg {
        color: var(--error-color);
        margin-top: -3px;
        cursor: text;
      }
    }

    .optional {
      font-size: 16px;
      color: var(--color-warning);

      svg {
        color: var(--color-warning);
        margin-top: -3px;
        cursor: text;
      }
    }

    .docusign-logo {
      margin-left: 15px;
      vertical-align: text-top;
    }
  }

  .data {
    color: var(--color-text);
    padding: 0;
    font-size: 16px;
    line-height: 32px;

    .warning {
      color: var(--primary-color);
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
        color: var(--color-warning);
      }
    }

    .linked {
      color: var(--primary-color);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--primary-color);
        font-size: 32px;
      }

      .field-label {
        text-align: right;
      }
    }

    .not-linked {
      color: var(--color-warning);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--color-warning);
        font-size: 32px;
      }
    }

    .add-users {
      max-width: 200px;
    }

    .next-steps {
      color: var(--primary-color);
    }

    .edit-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .man {
      max-width: 240px !important;
    }

    .man-list {
      margin-top: 20px;
      border-top: 1px dashed var(--secondary-color);
    }

    .btn.mini {
      max-height: 40px;
    }

    .upload-date {
      font-size: 14px;
    }

    strong {
      padding-right: 4px;
    }

    @media screen and (min-width: 770px) {
      .upload-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .add-man-col {
        display: flex;
      }
    }

    .separator {
      border-top: 1px dashed var(--secondary-color);
      margin-top: 20px;
    }

    .faux-upload {
      display: inline-block;
      padding: 0px;

      ::v-deep .v-input__slot {
        &:before,
        &:after {
          border-color: transparent !important;
          text-decoration-color: transparent !important;
          background-color: transparent;
        }

        .v-input__append-inner {
          width: 0px;
        }

        .v-text-field__slot {
          display: none;
        }

        .pdf {
          margin-right: 8px;
          color: #fff;
        }
      }
    }

    .logo-upload {
      padding: 20px;
      border: 2px dashed var(--secondary-color);
      background-color: var(--input-bg-color);
      line-height: 18px;
      text-align: center;

      .camera-logo {
        margin-left: 5px;
      }
    }

    .theme-button {
      align-self: center;
    }

    .link-area {
      display: flex;
      justify-content: space-between;
      color: var(--primaryColor);
      margin-bottom: 16px;
    }

    ::v-deep {
      .missing {
        font-weight: 500;
        font-size: 14px;
        color: var(--error-color);
      }
    }
  }

  .v-text-field.align-top {
    padding-top: 0;
    margin-top: 0;
  }
}
</style>
