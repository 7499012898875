/* eslint-disable no-unused-vars */
/*
    VUEX Advisor Module
*/
import Storage from '@/services/storage.service';
import { SESSION_KEYS, USER_ROLES } from '@/common/constants';
import { replaceEndpointSlugsFromState, replaceEndpointSlugs } from '@/utils';
import {
  SET_WORKING_CLIENT,
  GET_COMPANIES,
  ADD_COMPANY,
  UPDATE_COMPANY_INFO,
  UPDATE_COMPANY_ADDRESS,
  UPDATE_COMPANY_CRM,
  UPDATE_MOBILE_PHONE,
  MASTER_ACCOUNT_NUMBER,
  ADD_MASTER_ACCOUNT_NUMBER,
  DELETE_MASTER_ACCOUNT_NUMBER,
  GET_COMPANY,
  GET_CURRENT_COMPANY,
  UPDATE_STATUS,
  GET_USERS,
  GET_USER,
  ADD_USER,
  ADD_NEW_USER,
  EDIT_USER,
  GET_SELF,
  EDIT_SELF,
  GET_CLIENTS,
  LINK_REDTAIL,
  LINK_WEALTHBOX,
  UNLINK_CRM,
  CLIENT,
  GET_CLIENT,
  ADD_CLIENT,
  ADD_CLIENT_BATCH,
  EDIT_CLIENT,
  DELETE_CLIENT,
  DELETE_CLIENT_BATCH,
  RESTORE_CLIENT,
  ARCHIVE_CLIENT,
  SEND_CLIENT_BATCH,
  IMPERSONATE_CLIENT,
  GET_USER_ASSETS,
  GET_USER_ASSET,
  DOWNLOAD_USER_ASSET,
  DELETE_USER_ASSET,
  POST_USER_ASSET,
  GET_CRM_USERS,
  GET_TEAM_USERS,
  GET_CUSTOM_QUESTIONS,
  ADD_CUSTOM_QUESTION,
  DELETE_CUSTOM_QUESTION,
  REGISTER_EMAIL,
  DOCUSIGN_BASE,
  GET_DOCUSIGN_CONSENT_URL,
  CHOOSE_DOCUSIGN_ACCOUNT,
  CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP,
  UNLINK_DOCUSIGN,
  DOCUSIGN_USER_BASE,
  CHOOSE_DOCUSIGN_USER_ACCOUNT,
  CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP,
  UNLINK_DOCUSIGN_USER,
  GET_REVIEW_ENVELOPE_URL,
  GET_TRANSITION_PUBLIC_INFO,
  GET_ONBORD_DOCUMENTS,
  GENERATE_ADVISORY_AGREEMENT,
  SEARCH_CRM_CONTACTS,
  GET_CURRENT_USER,
  GET_DOCUSIGN_DOCUMENTS,
  UPSERT_DOCUSIGN_DOCUMENT,
  GET_AVAILABLE_CRMS,
  TOGGLE_DOCUSIGN,
  TOGGLE_TRANSITIONS,
  TOGGLE_TRANSITION_NOTIFICATIONS,
  TOGGLE_SMS_NOTIFICATIONS,
  GET_CUSTOM_QUESTIONS_TEMPLATES,
  GET_CUSTOM_QUESTIONS_TEMPLATE,
  ADD_CUSTOM_QUESTION_TEMPLATE,
  DELETE_CUSTOM_QUESTION_TEMPLATE,
  UPDATE_CUSTOM_QUESTION_TEMPLATE,
  GET_TRANSITIONING_CLIENTS,
  GET_TRANSITION,
  EDIT_TRANSITION,
  DELETE_TRANSITION,
  DELETE_BATCH_TRANSITIONS,
  TRANSITION_BATCH,
  SET_WORKING_TRANSITION,
  SET_SELECTED_TRANSITION_TYPE,
  ADD_TRANSITION_NAME,
  ADD_TRANSITION_PHONE,
  ADD_TRANSITION_EMAIL,
  TRANSITION_ADD_SECOND,
  ADD_FIRM_ROLES,
  TRANSITION_CLIENTS,
  ADD_TRANSITION_HOUSEHOLDS,
  EMPTY_TRANSITION_HOUSEHOLDS,
  STORE_HOUSEHOLD_VALUE,
  EMPTY_TRANSITIONING_HOUSEHOLD,
  DOWNLOAD_CSV_CLIENTS_TEMPLATE,
  DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE,
  DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE,
  UPDATE_TRANSITIONS_SMS_TEMPLATE,
  SEND_TRANSITION_SMS,
  SEND_BATCH_TRANSITION_SMS,
  GET_TRANSITION_URL,
  GET_CLIENT_URL,
} from '@/stores/actions.type';
import {
  SET_CURRENT_CLIENT,
  SET_CURRENT_COMPANY,
  SET_HOUSEHOLD_VALUE,
  SET_TRANSITION,
  SET_TRANSITION_NAME,
  SET_TRANSITION_EMAIL,
  SET_TRANSITION_PHONE,
  SET_ADD_SECOND,
  SET_FIRM_ROLES,
  SET_TRANSITION_HOUSEHOLDS,
  RESET_TRANSITION_HOUSEHOLDS,
  RESET_TRANSITIONING_HOUSEHOLD,
} from '@/stores/mutations.type';
import ApiService from '@/services/api.service';
import { ActionApiRouteMapper } from '../actions.type';
import { FORMATTERS } from '../../common/formats';
import { organizationsMapper } from '../../utils/responseMapper/mappers/organizationsMapper';

const baseClient = function () {
  return {
    client1: null,
    client2: null,
  };
};

const baseHousehold = {
  primaryContact: { firstName: '', lastName: '', phone: '', email: '' },
  secondaryContact: { firstName: '', lastName: '', phone: '', email: '' },
  addSecond: 'N',
  firmRoles: {},
  assetsValue: null,
};

// State Data
const state = {
  currentUser: Storage.get(SESSION_KEYS.CURRENT_USER),
  currentClient: Storage.get(SESSION_KEYS.CURRENT_CLIENT) || baseClient(),
  currentCompany: Storage.get(SESSION_KEYS.CURRENT_COMPANY),
  selectedTransitionType:
    Storage.get(SESSION_KEYS.SELECTED_TRANSITION_TYPE) || null,
  transitioningHousehold: Storage.get(SESSION_KEYS.TRANSITION_HOUSEHOLD) || {
    transitionId: '',
    primaryContact: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      crmId: '',
    },
    secondaryContact: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      crmId: '',
    },
    addSecond: 'N',
    firmRoles: {},
    assetsValue: null,
  },
  transitionHouseholds: Storage.get(SESSION_KEYS.TRANSITION_HOUSEHOLDS) || [],
};

// Getters
const getters = {
  currentClient(state) {
    return state.currentClient;
  },
  currentCompany(state) {
    return state.currentCompany;
  },
  mobilePhone(state) {
    return state.mobilePhone;
  },
  selectedTransitionType(state) {
    return state.selectedTransitionType;
  },
  transitioningHousehold(state) {
    return state.transitioningHousehold;
  },
  transitionHouseholds(state) {
    return state.transitionHouseholds;
  },
  householdValue(state) {
    return state.householdValue;
  },
};

const actions = {
  // Store Working Client
  [SET_WORKING_CLIENT](context, client) {
    return new Promise((resolve) => {
      context.commit(SET_CURRENT_CLIENT, client);
      resolve();
    });
  },

  /**
   * Companies (Organisations)
   */
  [GET_COMPANIES](_, query) {
    let url = ActionApiRouteMapper.GET_COMPANIES;
    if (query) {
      url += FORMATTERS.query(query);
    }
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then((data) => {
          resolve(organizationsMapper(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_COMPANY](_, payload) {
    const url = ActionApiRouteMapper.ADD_COMPANY;

    return new Promise((resolve, reject) => {
      ApiService.post(url, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_COMPANY](_, id) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.GET_COMPANY, {
      organisationId: id,
    });

    return new Promise((resolve, reject) => {
      ApiService.get(url, GET_COMPANY)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_CURRENT_COMPANY]({ commit, rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CURRENT_COMPANY,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.get(url, GET_CURRENT_COMPANY)
        .then((data) => {
          commit(SET_CURRENT_COMPANY, data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_COMPANY_INFO](_, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.UPDATE_COMPANY_INFO, {
      organisationId: payload.organisationId,
    });

    const dto = Object.entries(payload).reduce((acc, [key, value]) => {
      if (key !== 'organisationId') {
        acc[key] = value;
      }
      return acc;
    }, {});

    return new Promise((resolve, reject) => {
      ApiService.put(url, dto, UPDATE_COMPANY_INFO)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_COMPANY_CRM](_, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.UPDATE_COMPANY_CRM, {
      organisationId: payload.organisationId,
    });

    const dto = Object.entries(payload).reduce((acc, [key, value]) => {
      if (key !== 'organisationId') {
        acc[key] = value;
      }
      return acc;
    }, {});

    return new Promise((resolve, reject) => {
      ApiService.put(url, dto)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_COMPANY_ADDRESS](_, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPDATE_COMPANY_ADDRESS,
      { organisationId: payload.organisationId },
    );

    const dto = Object.entries(payload).reduce((acc, [key, value]) => {
      if (key !== 'organisationId') {
        acc[key] = value;
      }
      return acc;
    }, {});

    return new Promise((resolve, reject) => {
      ApiService.put(url, dto)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_MOBILE_PHONE]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPDATE_MOBILE_PHONE,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.put(url, payload, UPDATE_MOBILE_PHONE)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MASTER_ACCOUNT_NUMBER](_, payload) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.MASTER_ACCOUNT_NUMBER,
      {
        organisationId: payload.organisationId,
      },
    );

    return ApiService.get(url);
  },
  [ADD_MASTER_ACCOUNT_NUMBER](_, payload) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.ADD_MASTER_ACCOUNT_NUMBER,
      {
        organisationId: payload.organisationId,
      },
    );

    return new Promise((resolve, reject) => {
      ApiService.post(url, {
        masterAccount: payload.man,
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_MASTER_ACCOUNT_NUMBER](_, payload) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.DELETE_MASTER_ACCOUNT_NUMBER,
      {
        organisationId: payload.organisationId,
      },
    );

    return new Promise((resolve, reject) => {
      ApiService.delete(`${url}/${payload.man}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPDATE_STATUS](_, { organisationId, status }) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.UPDATE_STATUS, {
      organisationId,
    });

    return new Promise((resolve, reject) => {
      ApiService.post(url, { status })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_DOCUSIGN_DOCUMENTS]({ rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_DOCUSIGN_DOCUMENTS,
      rootState,
    );

    return ApiService.get(url);
  },
  [UPSERT_DOCUSIGN_DOCUMENT]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_DOCUSIGN_DOCUMENTS,
      rootState,
    );

    let formData = new FormData();
    formData.append('file', payload.file);

    return new Promise((resolve, reject) => {
      ApiService.put(
        `${url}?fileName=${payload.fileName.replace(' ', '')}`,
        formData,
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_AVAILABLE_CRMS](_, payload) {
    const url = ActionApiRouteMapper.GET_AVAILABLE_CRMS.replace(
      ':organisationsId',
      payload.companyId,
    );

    return ApiService.get(url);
  },
  [TOGGLE_DOCUSIGN](_, payload) {
    const url = ActionApiRouteMapper.TOGGLE_DOCUSIGN.replace(
      ':organisationsId',
      payload.companyId,
    );

    return new Promise((resolve, reject) => {
      ApiService.patch(url, {
        feature: 'docusign',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TOGGLE_TRANSITIONS](_, payload) {
    const url = ActionApiRouteMapper.TOGGLE_TRANSITIONS.replace(
      ':organisationsId',
      payload.companyId,
    );

    return new Promise((resolve, reject) => {
      ApiService.patch(url, {
        feature: 'transitions',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TOGGLE_TRANSITION_NOTIFICATIONS](_, payload) {
    const url = ActionApiRouteMapper.TOGGLE_TRANSITION_NOTIFICATIONS.replace(
      ':organisationsId',
      payload.companyId,
    );

    return new Promise((resolve, reject) => {
      ApiService.patch(url, {
        feature: 'transitionNotifications',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TOGGLE_SMS_NOTIFICATIONS](_, payload) {
    const url = ActionApiRouteMapper.TOGGLE_SMS_NOTIFICATIONS.replace(
      ':organisationsId',
      payload.companyId,
    );

    return new Promise((resolve, reject) => {
      ApiService.patch(url, {
        feature: 'smsNotificationsEnabled',
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Users (Advisors)
   **/
  [GET_USERS]({ rootState }, query) {
    let url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_USERS,
      rootState,
    );

    if (query) {
      url += FORMATTERS.query(query);
    }

    return ApiService.get(url, GET_USERS);
  },
  [GET_USER]({ state }, id) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.GET_USER, {
      advisorId: id,
      organisationId: state.currentCompany.id,
    });
    return ApiService.get(url, GET_USER);
  },
  [ADD_NEW_USER](context, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.ADD_NEW_USER, {
      organisationId: payload.organisationId,
    });
    return ApiService.post(url, payload);
  },

  [ADD_USER](context, payload) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();

      // Directly append the `organisationId` if it's expected to be in the payload
      formData.append('organisationId', context.state.currentCompany.id);

      formData.append('personalInfo[firstName]', payload.firstName);
      formData.append('personalInfo[lastName]', payload.lastName);
      formData.append('personalInfo[email]', payload.email);
      formData.append('personalInfo[phone]', payload.phone);

      formData.append('role', payload.role);

      // Check if there is a file to append
      if (payload.adv2b) {
        formData.append('adv2B', payload.adv2b, payload.adv2b.name);
      }

      // Construct the URL
      const url = replaceEndpointSlugs(ActionApiRouteMapper.ADD_USER, {
        organisationId: context.state.currentCompany.id,
      });

      // Make the API call
      return ApiService.post(url, formData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  [EDIT_USER](context, payload) {
    const { advisorId, organisationId } = payload;
    const url = replaceEndpointSlugs(ActionApiRouteMapper.EDIT_USER, {
      advisorId,
      organisationId,
    });
    return new Promise((resolve, reject) =>
      ApiService.put(url, payload)
        .then(() => {
          context.dispatch(GET_USER, advisorId, organisationId);
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error)),
    );
  },
  [GET_USER_ASSETS](context, id) {
    return ApiService.get(GET_USER_ASSETS);
  },
  //  TODO:
  [DELETE_USER_ASSET](context, payload) {
    return ApiService.delete(DELETE_USER_ASSET);
  },
  //  TODO:
  [GET_USER_ASSET](context, payload) {
    return ApiService.get(GET_USER_ASSET);
  },
  [DOWNLOAD_USER_ASSET]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DOWNLOAD_USER_ASSET,
      rootState,
    );
    return ApiService.get(
      `${url}?assetType=${payload.assetId}&assetId=${payload.assetId}`,
    );
  },
  [POST_USER_ASSET]({ rootState }, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.POST_USER_ASSET, {
      advisorId: payload.userId,
      organisationId: rootState.auth.currentUser.organisation.id,
    });

    let formData = new FormData();
    formData.append('file', payload.file);
    formData.append('assetType', payload.assetKey);
    return ApiService.upload(url, formData);
  },

  [GET_SELF](context, payload) {
    const { advisorId } = payload;
    const self = replaceEndpointSlugs(ActionApiRouteMapper.GET_SELF, advisorId);
    ApiService.get(self);
  },
  [EDIT_SELF](context, payload) {
    const { advisorId, organisationId } = payload;
    const url = replaceEndpointSlugs(ActionApiRouteMapper.EDIT_SELF, {
      advisorId,
      organisationId,
    });
    return new Promise((resolve, reject) => {
      ApiService.put(url, payload)
        .then(() => {
          context.dispatch(GET_USER, advisorId, organisationId);
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Clients
   **/
  [GET_CLIENTS]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CLIENTS,
      rootState,
    );

    return ApiService.query(url, payload, GET_CLIENTS);
  },
  [GET_CLIENT]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CLIENT,
      rootState,
    ).replace(':clientsId', id);

    return ApiService.get(url, GET_CLIENT);
  },
  [ADD_CLIENT]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.ADD_CLIENT,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.post(url, payload, ADD_CLIENT)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ADD_CLIENT_BATCH]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.ADD_CLIENT_BATCH,
      rootState,
    );
    return ApiService.post(url, payload);
  },
  [EDIT_CLIENT]({ rootState }, payload) {
    const id = payload.id;
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CLIENT,
      rootState,
    ).replace(':clientsId', id);

    return new Promise((resolve, reject) => {
      ApiService.patch(url, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_CLIENT]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_CLIENT,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.delete(`${url}/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_CLIENT_BATCH]({ rootState }, ids) {
    const uniqueIds = [...new Set(ids)];

    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_CLIENT_BATCH,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.post(url, {
        clientIds: uniqueIds,
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [RESTORE_CLIENT]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.RESTORE_CLIENT,
      rootState,
    ).replace(':clientsId', id);

    return new Promise((resolve, reject) => {
      ApiService.patch(url, id)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [ARCHIVE_CLIENT]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.ARCHIVE_CLIENT,
      rootState,
    ).replace(':clientsId', id);

    return new Promise((resolve, reject) => {
      ApiService.patch(url, id)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [SEND_CLIENT_BATCH]({ rootState }, ids) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.SEND_CLIENT_BATCH,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.post(url, {
        clientIds: ids,
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [IMPERSONATE_CLIENT](context, payload) {
    const url = ActionApiRouteMapper.IMPERSONATE_CLIENT.replace(
      ':clientsId',
      payload.id,
    );

    return new Promise((resolve, reject) => {
      ApiService.get(`${url}?relatesTo=${payload.relatesTo}`)
        .then((data) => {
          resolve({ token: data._id });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * CRM
   **/
  [GET_CRM_USERS]({ rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CRM_USERS,
      rootState,
    );
    return ApiService.get(url);
  },
  [GET_TEAM_USERS]({ rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_TEAM_USERS,
      rootState,
    );

    return ApiService.get(`${url}?limit=100&status=Active`, GET_TEAM_USERS);
  },
  [GET_CUSTOM_QUESTIONS]({ rootState }, params) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CUSTOM_QUESTIONS,
      rootState,
    );

    return ApiService.query(url, params, GET_CUSTOM_QUESTIONS);
  },
  [DELETE_CUSTOM_QUESTION]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_CUSTOM_QUESTION,
      rootState,
    );

    return ApiService.delete(`${url}/${payload.id}`);
  },
  [ADD_CUSTOM_QUESTION]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.ADD_CUSTOM_QUESTION,
      rootState,
    );

    return ApiService.post(url, payload, ADD_CUSTOM_QUESTION);
  },
  [LINK_REDTAIL]({ dispatch, rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.LINK_REDTAIL,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.put(url, payload)
        .then(({ data }) => {
          dispatch(GET_CURRENT_USER);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LINK_WEALTHBOX]({ dispatch, rootState }, payload) {
    //  TODO:  Implement this with new API method
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.LINK_WEALTHBOX,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.post(url, payload)
        .then((data) => {
          dispatch(GET_CURRENT_USER);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UNLINK_CRM]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const url = replaceEndpointSlugsFromState(
        ActionApiRouteMapper.UNLINK_CRM,
        rootState,
      );

      ApiService.put(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [REGISTER_EMAIL](context, payload) {
    // Assuming you have an API endpoint to handle email registration
    const url = ActionApiRouteMapper.REGISTER_EMAIL.replace(
      ':transitionsId',
      payload.transitionId,
    );

    return new Promise((resolve, reject) => {
      ApiService.post(`${url}?relativeTo=${payload.relatesTo}`, {
        email: payload.email,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [GET_TRANSITION_PUBLIC_INFO](context, payload) {
    const url = ActionApiRouteMapper.GET_TRANSITION_PUBLIC_INFO.replace(
      ':transitionsId',
      payload.transitionId,
    );

    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Docusign
   **/
  [GET_DOCUSIGN_CONSENT_URL](context, payload) {
    const url = ActionApiRouteMapper.GET_DOCUSIGN_CONSENT_URL;
    
    return new Promise((resolve, reject) => {
      ApiService.post(url, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //  TODO:
  [CHOOSE_DOCUSIGN_ACCOUNT]({rootState}, accountInfo) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.CHOOSE_DOCUSIGN_ACCOUNT,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.put(url, {
        accountId: accountInfo.accountId
      })
        .then((data) => {
         resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP]({ rootState }, ownership) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.put(url, {
        accountOwnership: ownership,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UNLINK_DOCUSIGN]({ rootState }) {
    return new Promise((resolve, reject) => {
      const url = replaceEndpointSlugsFromState(
        ActionApiRouteMapper.UNLINK_DOCUSIGN,
        rootState,
      );

      ApiService.put(url, UNLINK_DOCUSIGN)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHOOSE_DOCUSIGN_USER_ACCOUNT]({rootState}, accountInfo) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.CHOOSE_DOCUSIGN_USER_ACCOUNT,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.put(url, {
        accountId: accountInfo.accountId
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP]({ state }, payload) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP,
      {
        advisorId: payload.advisorId,
        organisationId: state.currentCompany.id,
      },
    );

    return new Promise((resolve, reject) => {
      ApiService.put(url, { accountOwnership: payload.ownership })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UNLINK_DOCUSIGN_USER]({ rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UNLINK_DOCUSIGN_USER,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.put(url, UNLINK_DOCUSIGN_USER)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_REVIEW_ENVELOPE_URL]({ rootState }, clientId) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_REVIEW_ENVELOPE_URL,
      rootState,
    ).replace(':clientsId', clientId);

    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //  TODO:
  [GET_ONBORD_DOCUMENTS](context, id) {
    return new Promise((resolve /*, reject*/) => {
      ApiService.downloadDirect(`${CLIENT}/${id}/downloadForms`);
      resolve(true);
    });
  },
  //  TODO:
  [GENERATE_ADVISORY_AGREEMENT](context, id) {
    return new Promise((resolve /*, reject*/) => {
      ApiService.downloadDirect(`${CLIENT}/${id}/generateAdvisoryAgreement`);
      resolve(true);
    });
  },
  [SEARCH_CRM_CONTACTS]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.SEARCH_CRM_CONTACTS,
      rootState,
    );

    return ApiService.query(url, payload, SEARCH_CRM_CONTACTS);
  },
  [DOWNLOAD_CSV_CLIENTS_TEMPLATE]({ rootState }, payload) {
    return new Promise((resolve /*, reject*/) => {
      ApiService.downloadDirect(
        ActionApiRouteMapper.DOWNLOAD_CSV_CLIENTS_TEMPLATE,
      );
      resolve(true);
    });
  },
  [DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE]() {
    return new Promise((resolve /*, reject*/) => {
      ApiService.downloadDirect(
        ActionApiRouteMapper.DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE,
      );
      resolve(true);
    });
  },
  [DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE]() {
    return new Promise((resolve /*, reject*/) => {
      ApiService.downloadDirect(
        ActionApiRouteMapper.DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE,
      );
      resolve(true);
    });
  },
  /**
   * Transitions
   **/
  [GET_TRANSITIONING_CLIENTS]({ rootState }) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.GET_TRANSITIONING_CLIENTS,
      {
        organisationId: rootState.advisor.currentCompany.id,
      },
    );
    return ApiService.get(url, GET_TRANSITIONING_CLIENTS);
  },
  [GET_TRANSITION]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_TRANSITION,
      rootState,
    ).replace(':transitionsId', id);

    return ApiService.get(url, GET_TRANSITION);
  },
  [EDIT_TRANSITION]({ rootState }, payload) {
    const id = payload.id;
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.EDIT_TRANSITION,
      rootState,
    ).replace(':transitionsId', id);

    return new Promise((resolve, reject) => {
      ApiService.put(url, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [DELETE_TRANSITION]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_TRANSITION,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.delete(`${url}/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DELETE_BATCH_TRANSITIONS]({ rootState }, transitionIds) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_BATCH_TRANSITIONS,
      rootState,
    );
    return new Promise((resolve, reject) => {
      ApiService.patch(`${url}`, { transitionIds })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRANSITION_BATCH]({ rootState }, payload, transitionType) {
    const formData = new FormData();
    formData.append('file', payload.file);
    const query = {
      transitionType: payload.transitionType,
    };

    const url =
      replaceEndpointSlugsFromState(
        ActionApiRouteMapper.TRANSITION_BATCH,
        rootState,
      ) + FORMATTERS.query(query);

    return new Promise((resolve, reject) => {
      ApiService.post(url, formData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_TRANSITION_URL]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_TRANSITION_URL,
      rootState,
    ).replace(':transitionsId', id);
    return ApiService.get(url);
  },
  [GET_CLIENT_URL]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CLIENT_URL,
      rootState,
    ).replace(':clientId', id);
    return ApiService.get(url);
  },

  [UPDATE_TRANSITIONS_SMS_TEMPLATE]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPDATE_TRANSITIONS_SMS_TEMPLATE,
      rootState,
    );

    return new Promise((resolve, reject) => {
      ApiService.put(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [SEND_TRANSITION_SMS](_, { transitionId, organisationId, smsPayload }) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.SEND_TRANSITION_SMS, {
      organisationId,
      transitionId,
    });

    return new Promise((resolve, reject) => {
      ApiService.post(url, { smsPayload })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [SEND_BATCH_TRANSITION_SMS](_, { transitionIds, organisationId, smsPayload }) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.SEND_BATCH_TRANSITION_SMS, {
      organisationId,
    });

    return new Promise((resolve, reject) => {
      ApiService.post(url, { smsPayload, transitionIds })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [SET_WORKING_TRANSITION](context, transition) {
    return new Promise((resolve) => {
      context.commit(SET_TRANSITION, transition);
      resolve();
    });
  },
  [SET_SELECTED_TRANSITION_TYPE](context, payload) {
    return new Promise((resolve) => {
      context.commit(SET_SELECTED_TRANSITION_TYPE, payload);
      resolve();
    });
  },
  [ADD_TRANSITION_NAME](context, payload) {
    context.commit(SET_TRANSITION_NAME, payload);
  },
  [ADD_TRANSITION_PHONE](context, payload) {
    context.commit(SET_TRANSITION_PHONE, payload);
  },
  [ADD_TRANSITION_EMAIL](context, payload) {
    context.commit(SET_TRANSITION_EMAIL, payload);
  },
  [TRANSITION_ADD_SECOND](context, value) {
    context.commit(SET_ADD_SECOND, value);
  },

  [ADD_FIRM_ROLES](context, value) {
    context.commit(SET_FIRM_ROLES, value);
  },
  [TRANSITION_CLIENTS]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.TRANSITION_CLIENTS,
      rootState,
    );

    return ApiService.post(url, payload);
  },
  [ADD_TRANSITION_HOUSEHOLDS](context, household) {
    context.commit(ADD_TRANSITION_HOUSEHOLDS, household);
  },
  [SET_TRANSITION_HOUSEHOLDS](context, households) {
    context.commit(SET_TRANSITION_HOUSEHOLDS, households);
  },
  [EMPTY_TRANSITION_HOUSEHOLDS](context) {
    context.commit(RESET_TRANSITION_HOUSEHOLDS);
  },
  [STORE_HOUSEHOLD_VALUE](context, householdValue) {
    return new Promise((resolve) => {
      context.commit(SET_HOUSEHOLD_VALUE, householdValue);
      resolve();
    });
  },
  [EMPTY_TRANSITIONING_HOUSEHOLD](context) {
    context.commit(RESET_TRANSITIONING_HOUSEHOLD);
  },

  /**
   * Custom Question Templates
   **/
  [GET_CUSTOM_QUESTIONS_TEMPLATES]({ rootState }, query) {
    let url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CUSTOM_QUESTIONS_TEMPLATES,
      rootState,
    );
    if (query) {
      url += FORMATTERS.query(query);
    }

    return ApiService.get(url);
  },

  [GET_CUSTOM_QUESTIONS_TEMPLATE]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_CUSTOM_QUESTIONS_TEMPLATE,
      rootState,
    ).replace(':templatesId', id);

    return ApiService.get(url);
  },

  [ADD_CUSTOM_QUESTION_TEMPLATE]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.ADD_CUSTOM_QUESTION_TEMPLATE,
      rootState,
    );

    return ApiService.post(url, payload);
  },

  [DELETE_CUSTOM_QUESTION_TEMPLATE]({ rootState }, id) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.DELETE_CUSTOM_QUESTION_TEMPLATE,
      rootState,
    ).replace(':templatesId', id);

    return ApiService.delete(url);
  },

  [UPDATE_CUSTOM_QUESTION_TEMPLATE]({ rootState }, payload) {
    const id = payload.id;
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPDATE_CUSTOM_QUESTION_TEMPLATE,
      rootState,
    ).replace(':templatesId', id);

    return ApiService.put(url, payload);
  },
};

const mutations = {
  /* Set the current user and store in session */
  [SET_CURRENT_CLIENT](state, client) {
    state.currentClient = client;
    if (client === null) {
      state.currentClient = baseClient();
      Storage.remove(SESSION_KEYS.CURRENT_CLIENT);
    } else {
      Storage.set(SESSION_KEYS.CURRENT_CLIENT, state.currentClient);
    }
  },
  [SET_CURRENT_COMPANY](state, company) {
    state.currentCompany = { ...company };
    Storage.set(SESSION_KEYS.CURRENT_COMPANY, state.currentCompany);
  },

  [SET_SELECTED_TRANSITION_TYPE](state, payload) {
    state.selectedTransitionType = payload;
    Storage.set(
      SESSION_KEYS.SELECTED_TRANSITION_TYPE,
      state.selectedTransitionType,
    );
  },

  [SET_HOUSEHOLD_VALUE](state, householdValue) {
    state.transitioningHousehold.assetsValue = householdValue;
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_TRANSITION](state, transition) {
    state.transitioningHousehold = transition;
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_TRANSITION_NAME](state, payload) {
    const { key, firstName, lastName } = payload;
    if (key === 0) {
      state.transitioningHousehold.primaryContact.firstName = firstName;
      state.transitioningHousehold.primaryContact.lastName = lastName;
    } else if (key === 1) {
      state.transitioningHousehold.secondaryContact.firstName = firstName;
      state.transitioningHousehold.secondaryContact.lastName = lastName;
    }
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_TRANSITION_EMAIL](state, payload) {
    const { key, email } = payload;
    if (key === 0) {
      state.transitioningHousehold.primaryContact.email = email;
    } else if (key === 1) {
      state.transitioningHousehold.secondaryContact.email = email;
    }
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_TRANSITION_PHONE](state, payload) {
    const { key, phone } = payload;
    if (key === 0) {
      state.transitioningHousehold.primaryContact.phone = phone;
    } else if (key === 1) {
      state.transitioningHousehold.secondaryContact.phone = phone;
    }
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_ADD_SECOND](state, value) {
    state.transitioningHousehold.addSecond = value;
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [SET_FIRM_ROLES](state, firmRoles) {
    state.transitioningHousehold.firmRoles = firmRoles;
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
  [ADD_TRANSITION_HOUSEHOLDS](state, household) {
    const alreadyExists = state.transitionHouseholds.some(
      ({ primaryContact }) =>
        primaryContact.phone === household.primaryContact.phone,
    );

    if (!alreadyExists) {
      state.transitionHouseholds.push(household);
    }

    Storage.set(SESSION_KEYS.TRANSITION_HOUSEHOLDS, state.transitionHouseholds);
  },
  [SET_TRANSITION_HOUSEHOLDS](state, households) {
    state.transitionHouseholds = households;
    Storage.set(SESSION_KEYS.TRANSITION_HOUSEHOLDS, state.transitionHouseholds);
  },
  [RESET_TRANSITION_HOUSEHOLDS](state) {
    state.transitionHouseholds = [];
    Storage.set(SESSION_KEYS.TRANSITION_HOUSEHOLDS, state.transitionHouseholds);
  },
  [RESET_TRANSITIONING_HOUSEHOLD](state) {
    state.transitioningHousehold = baseHousehold;
    Storage.set(
      SESSION_KEYS.TRANSITION_HOUSEHOLD,
      state.transitioningHousehold,
    );
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
