<template>
  <div class="step-container advisor">
    <v-dialog
      v-model="dialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">
            Are you ready to send this off to your clients?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <v-radio-group
                  v-model="saveDecision"
                  hide-details="auto"
                  class="pb-7"
                >
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio mb-4"
                    label="Yes"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio"
                    label="No, I'll do it later"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <a
                  @click="saveComplete()"
                  v-if="saveDecision !== null"
                  class="btn mt-3"
                  >Save and Complete</a
                >
                <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="notificationDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">
            How would you like notification to be sent out to the client?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <div v-for="(notification, i) in notificationMethodOptions" :key="i">
                  <v-checkbox
                    v-model="notificationMethods"
                    hide-details
                    :color="primaryColor"
                    :ripple="false"
                    :value="notification.value"
                    :disabled="notification.disabled"
                  >
                    <template v-slot:label>
                      <span class="cb-label" :class="{ 'disabled': notification.disabled }">{{notification.text}}</span>
                    </template>
                  </v-checkbox>
                </div>
                <a
                  @click="saveNotificationInfo()"
                  class="btn mt-3"
                  >Save</a
                >
                <a @click="cancelNotificationInfo()" class="btn secondary mt-3">Cancel</a>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form name="AccountFeatures" class="form">
      <v-container class="steps-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Congrats! Let's <strong>review &amp; finish.</strong>
              </span>
            </h1>
          </v-col>
        </v-row>
        <div class="justify-center steps-table">
          <v-row class="editable first">
            <v-col
              class="col-4 step-col first"
              :class="isClientSelectionComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientSelectionComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client Selections </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                <span class="detail-header"> Client 1 Type: </span>
                <span class="detail-item"> {{ clientOne.type }} </span>
                <br />
                <span v-if="clientOne.preserveInterview">
                  <i> Preserve Interview Answers </i> <br />
                </span>
                <br />
                <span class="detail-header"> Has Second Client: </span>
                <span class="detail-item"> {{ secondClient }} </span>
                <br />
                <span v-if="secondClient === 'Yes'">
                  <span class="detail-header"> Client 2 Type: </span>
                  <span class="detail-item"> {{ clientTwo.type }} </span>
                  <br />
                </span>
                <span
                  v-if="secondClient === 'Yes' && clientOne.preserveInterview"
                >
                  <i> Preserve Interview Answers </i><br />
                </span>
                <br />
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_SELECTION)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col"
              :class="isDataSelectionComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isDataSelectionComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Data Selection </span>
            </v-col>
            <v-col class="col-8 data-col">
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.DATA_SELECTION)"
              >
                <i class="fas fa-pencil"></i>
              </div>
              <div class="val">
                <span class="detail-header"> Open Accounts: </span>
                <span class="detail-item">
                  {{ currentClient.addAccountsSelected ? 'Yes' : 'No' }}
                </span>
                <br />
                <span class="detail-header"> DocuSign: </span>
                <span class="detail-item">
                  {{ currentClient.esignEnabled ? 'Yes' : 'No' }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col first"
              :class="isClientOneNameComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientOneNameComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 1 Name </span>
            </v-col>
            <v-col class="col-8 data-col">
              <div class="val">
                {{ clientOneName }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col first"
              :class="isClientOneEmailComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientOneEmailComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 1 Email Address </span>
            </v-col>
            <v-col class="col-8 data-col">
              <div class="val">
                {{ clientOneEmail }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_ONE_EMAIL)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col first"
              :class="isClientOnePhoneComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientOnePhoneComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 1 Mobile Phone </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                {{ clientOneFormattedPhone }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="addedSecondClient">
            <v-col
              class="col-4 step-col first"
              :class="isClientTwoNameComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientTwoNameComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 2 Name </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                {{ clientTwoName }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="addedSecondClient">
            <v-col
              class="col-4 step-col first"
              :class="isClientTwoEmailComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientTwoEmailComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 2 Email Address </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                {{ clientTwoEmail }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="addedSecondClient">
            <v-col
              class="col-4 step-col first"
              :class="isClientTwoPhoneComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isClientTwoPhoneComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Client 2 Mobile Phone </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                {{ clientTwoFormattedPhone }}
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="canAddAccounts">
            <v-col
              class="col-4 step-col first"
              :class="isAccountSelectionComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isAccountSelectionComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Account Selections </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                <div v-for="account in accountSelections" :key="account.label">
                  <div class="detail-header">
                    {{ account.type }} ({{ account.label }})
                  </div>
                  <div class="detail-subheader" v-if="account.ownership">
                    {{ account.ownership }}
                  </div>
                  <div
                    class="detail-listitem"
                    v-for="(client, index) in account.clients"
                    :key="`${account.name}${index}`"
                  >
                    <b>Client</b>: {{ client }}
                  </div>
                </div>
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="canAddAccounts">
            <v-col
              class="col-4 step-col first"
              :class="isAccountFeaturesComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isAccountFeaturesComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Feature Selections </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                <div
                  v-for="client in accountFeatureSelections"
                  :key="client.name"
                >
                  <div class="detail-header">{{ client.name }}</div>
                  <div
                    v-for="(account, index) in client.features"
                    :key="`${account.nickname}${index}`"
                  >
                    <div class="detail-subheader">
                      {{ account.name }} ({{ account.nickname }})
                    </div>
                    <div class="detail-item">
                      Master Account Number:
                      <span v-if="account.masterAccountNumber">
                        {{ account.masterAccountNumber }}
                      </span>
                      <span v-else class="missing"> &lt;missing&gt; </span>
                    </div>

                    <div class="detail-listitem">
                      {{ featuresList(account) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="canAddAccounts">
            <v-col
              class="col-4 step-col first"
              :class="isAdvisoryRateComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isAdvisoryRateComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Advisory Rates </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val" v-if="advisoryRate">
                <div class="detail-header">{{ advisoryRate }}</div>
                <div v-for="client in rateSelections" :key="client.name">
                  <div class="detail-subheader small">
                    {{ client.name }}
                  </div>
                  <div
                    v-for="(account, index) in client.features"
                    :key="`${account.nickname}${index}`"
                  >
                    <div class="detail-listitem">
                      {{
                        `${account.name} (${
                          account.nickname
                        }) - ${getAccountRatePercent(account.rate)}`
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.ADVISORY_RATE)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col first"
              :class="isFirmRolesComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isFirmRolesComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Firm Roles </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div class="val">
                <div>
                  <div class="detail-header">Primary Advisor</div>
                  <div class="detail-listitem">
                    {{
                      currentClient.firmRoles &&
                      currentClient.firmRoles.primaryAdvisor
                        ? currentClient.firmRoles.primaryAdvisor.name ||
                          '&lt;none&gt;'
                        : '&lt;none&gt;'
                    }}
                    {{
                      currentClient.firmRoles &&
                      currentClient.firmRoles.primaryAdvisor &&
                      !currentClient.firmRoles.primaryAdvisor.canSend
                        ? '&lt; Advisor Send DISABLED &gt;'
                        : ''
                    }}
                  </div>
                </div>
                <div>
                  <div class="detail-header">Primary CSA</div>
                  <div class="detail-listitem">
                    {{
                      currentClient.firmRoles &&
                      currentClient.firmRoles.primaryCSA
                        ? currentClient.firmRoles.primaryCSA.name ||
                          '&lt;none&gt;'
                        : '&lt;none&gt;'
                    }}
                  </div>
                </div>
                <div>
                  <div class="detail-header">Advisors Providing Advice</div>
                  <div
                    v-if="
                      currentClient.firmRoles &&
                      currentClient.firmRoles.provideAdvice.length > 0
                    "
                  >
                    <div
                      v-for="user in currentClient.firmRoles.provideAdvice"
                      :key="user"
                      class="detail-listitem"
                    >
                      {{ user.name }}
                    </div>
                  </div>
                  <div class="detail-listitem" v-else>&lt;none&gt;</div>
                </div>
                <div>
                  <div class="detail-header">Secondary CSA</div>
                  <div
                    v-if="
                      currentClient.firmRoles &&
                      currentClient.firmRoles.secondaryCSA.length > 0
                    "
                  >
                    <div
                      v-for="user in currentClient.firmRoles.secondaryCSA"
                      :key="user"
                      class="detail-listitem"
                    >
                      {{ user.name }}
                    </div>
                  </div>
                  <div class="detail-listitem" v-else>&lt;none&gt;</div>
                </div>
              </div>
              <div class="edit" @click="next(MENUS.ADVISOR.CLIENT.FIRM_ROLES)">
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
          <v-row class="section-row editable" v-if="canAddCustomTemplates">
            <v-col
              class="col-4 step-col first"
              :class="isCustomQuestionComplete ? '' : 'error'"
            >
              <span class="section-name"> Custom Templates </span>
            </v-col>
            <v-col class="col-8 data-col">
              <div
                class="val"
                v-if="selectedTemplates && selectedTemplates.length > 0"
              >
                <ul>
                  <li v-for="template in selectedTemplates" :key="template._id">
                    {{ template.templateName }}
                  </li>
                </ul>
              </div>
              <div class="val" v-else>No custom questions have been added.</div>
              <div
                class="edit"
                @click="next(MENUS.ADVISOR.CLIENT.CUSTOM_QUESTIONS)"
              >
                <i class="fas fa-pencil"></i>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      v-on:go-prev="prev()"
      v-on:go-next="finish()"
      :nextText="finishText"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import {
  MENUS,
  FEATURES_TYPES,
  JOINT_NAME_BROKERAGE_TYPES,
} from '@/common/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountFeatures',

  mixins: [AdvisorClientMixin],

  components: { BottomNav },

  watch: {
    saveDecision(newPayload) {
      this.notificationDialog = newPayload
    },
    notificationDialog(isOpen) {
      if (isOpen) {
        // When dialog opens, set default notification methods based on SMS availability
        this.setDefaultNotificationMethods();
      }
    },
    currentCompany: {
      immediate: true,
      handler(company) {
        // Update notification options based on SMS availability
        this.notificationMethodOptions = this.notificationMethodOptions.map(option => ({
          ...option,
          disabled: option.value === 'sms' && company && !company.smsNotificationsEnabled
        }));

        // Set default notification methods when company data changes
        this.setDefaultNotificationMethods();
      }
    }
  },

  data: () => ({
    MENUS: MENUS,
    dialog: false,
    saveDecision: null,
    forms: {
      client1: {},
      client2: {},
    },
    dataSelection: null,
    secondClient: null,
    accountSelections: [],
    accountFeatureSelections: [],
    rateSelections: [],
    notificationDialog: null,
    notificationMethods: ['email'],
    notificationMethodOptions: [
      { value: 'sms', text: 'SMS', disabled: false },
      { value: 'email', text: 'Email', disabled: false }
    ]
  }),
  validations() {
    return {};
  },
  computed: {
    ...mapGetters(['currentCompany']),
    /* Dynamic errors */
    finishErrors() {
      return this.getSaveMessage();
    },
    finishText() {
      return this.areAllStepsComplete && this.canSend ? 'Finish' : 'Save';
    },
    canSend() {
      const {
        firmRoles: {
          primaryAdvisor: { canSend },
        },
      } = this.currentClient || {};

      return canSend;
    },
    canNotify() {
      return this.saveDecision ? true : false;
    },
    availableNotificationMethods() {
      const methods = ['email'];
      if (this.currentCompany && this.currentCompany.smsNotificationsEnabled) {
        methods.push('sms');
      }
      return methods;
    }
  },

  methods: {
    /* Set default notification methods based on SMS availability */
    setDefaultNotificationMethods() {
      this.notificationMethods = ['email'];
      if (this.currentCompany && this.currentCompany.smsNotificationsEnabled) {
        this.notificationMethods.push('sms');
      }
    },

    /* Map form data to working client */
    mapData() {
      // Do Nothing
    },

    /* Cancel the save confirmation */
    cancel() {
      this.dialog = false;
      this.saveDecision = null;
    },

    /* Get the features data needed for the view summary */
    featuresList(account) {
      let list = [];

      Object.keys(account).forEach((k) => {
        if (account[k]) {
          switch (k) {
            case 'moveMoney':
              list.push(FEATURES_TYPES.MONEY_LINK);
              break;
            case 'acat':
              list.push(FEATURES_TYPES.ACAT);
              break;
            case 'iraDistribution':
              list.push(FEATURES_TYPES.IRA_DISTRIBUTION);
              break;
            default:
              break;
          }
        }
      });

      return list.length > 0 ? list.join(', ') : 'None';
    },

    /* Get the Advisory Rate needed for the view summary */
    getAccountRatePercent(rate) {
      return rate !== null ? `${rate}%` : 'Not selected';
    },

    /* Initialize form data needed for the summary table */
    initializeForms() {
      this.dataSelection = this.currentClient.type;
      this.forms.client1.firstName = this.currentClient.client1.firstName;
      this.forms.client1.lastName = this.currentClient.client1.lastName;
      this.forms.client1.email = this.currentClient.client1.email;
      this.forms.client1.phone = this.currentClient.client1.phone;

      this.secondClient = this.isSecondClientSelectionComplete
        ? this.addedSecondClient
          ? 'Yes'
          : 'No'
        : '';

      if (this.currentClient.addedSecondClient) {
        this.forms.client1.firstName = this.currentClient.client2.firstName;
        this.forms.client1.lastName = this.currentClient.client2.lastName;
        this.forms.client2.email = this.currentClient.client2.email;
        this.forms.client2.phone = this.currentClient.client2.phone;
      }

      this.accountSelections = this.getAccountSelections();
      this.accountFeatureSelections = this.getAccountFeatureSelections();
      this.rateSelections = this.getAccountRateSelections();
      this.selectedTemplates = this.currentClient.selectedTemplates;
    },

    /* Save the working client and mark it as complete */
    saveComplete() {
      const payload = this.mapClientPayload();

      // Set send welcome email based on save decision from dialog
      payload.sendNow = this.saveDecision;
      payload.transitionId = this.currentClient.transitionId;
      payload.notificationMethods = this.notificationMethods;
      this.saveClient(payload);
    },
    saveNotificationInfo() {
      this.notificationDialog = false;
    },

    cancelNotificationInfo() {
      this.notificationDialog = false;
    },

    /* Save a working client that has no incomplete data */
    finish() {
      if (this.finishErrors) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: this.finishErrors,
          okTitle: 'Ok',
        });
      } else {
        // If all is valid, allow to finish
        if (this.areAllStepsComplete && this.canSend) {
          this.dialog = true;
        } else {
          // Simply Save
          const payload = this.mapClientPayload();
          payload.sendNow = false;
          payload.transitionId = this.currentClient.transitionId;

          this.saveClient(payload);
        }
      }
    },

    /* Nav to Previous Page in flow */
    prev() {
      let routeId = MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
      if (this.canAddCustomTemplates) {
        routeId = MENUS.ADVISOR.CLIENT.CUSTOM_QUESTIONS.id;
      }
      this.goPrev(routeId);
    },

    /* Nav to Route chosen */
    next(route = null) {
      const to = route ? (route.id ? route.id : route.name) : '';
      this.goNext(to);
    },
  },

  created() {
    // Initialize form data
    this.initializeForms();

    // Initialize static joint account types
    this.JOINT_NAME_BROKERAGE_TYPES = JOINT_NAME_BROKERAGE_TYPES;
  },
};
</script>

<style lang="scss" scoped>
.steps-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.steps-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .row {
    margin: 0;

    &.section-row {
      border-top: 2px solid var(--secondary-color);
    }
    &.editable {
      position: relative;
    }

    .edit {
      border: none !important;
      position: absolute;
      right: 8px;
      top: 20px;
      height: 16px;
      width: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color);
      }
    }

    .col {
      padding: 20px 26px;
      border-top: none;

      &.data-col {
        border-left: 2px solid var(--secondary-color);
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 960px) {
          border-left: none;
        }

        .val {
          border: none !important;
          font-weight: 500;
        }

        .button-area {
          margin-top: 20px;
          margin-bottom: 5px;

          .btn {
            margin-right: 15px;
          }
        }
      }

      &.step-col {
        background-color: var(--color-light-grey);

        @media screen and (max-width: 960px) {
          background-color: transparent;
          font-size: 18px;
          font-weight: 500;
          padding-bottom: 0px;
        }

        .error-icon {
          margin-right: 5px;
          display: inline-block;
        }
        .section-name {
          display: inline-block;
        }
        &.error {
          .section-name {
            vertical-align: text-top;
          }
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;
        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }
    }

    .data-area {
      flex-direction: column;
      padding-top: 20px;

      .col {
        padding: 0 8px 0 0;
      }
    }

    .detail-header {
      margin-top: 8px;
    }
    .detail-subheader {
      font-size: 16px;
      font-weight: 400;

      &.small {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .detail-item {
      font-size: 14px;
      font-weight: 400;

      .missing {
        color: var(--error-color);
      }
    }
    .detail-listitem {
      font-size: 14px;
      font-weight: 400;

      &:before {
        content: '\2022';
        margin-left: 5px;
        width: 10px;
        color: var(--color-text);
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.cb-label {
  padding-top: 5px;
  text-transform: capitalize;
  font-size: 18px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
